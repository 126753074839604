import React from "react"
import { Highlight, themes } from "prism-react-renderer"

export interface ICodeBlockProps {
  children?: React.ReactNode
  className?: string
}

const PrismSyntaxHighlight: React.FC<ICodeBlockProps> = props => {
  const { children, className } = props
  const language = className ? className.replace(/language-/, "") : ""

  const codeString = typeof children === "string" ? children.trim() : ""

  return (
    <Highlight
      theme={themes.shadesOfPurple}
      code={codeString}
      language={language}
    >
      {props => {
        const { tokens, getLineProps, getTokenProps } = props
        return (
          <code
            className={`${className} dark:bg-slate-900 block overflow-auto rounded-md bg-slate-800 p-8 leading-7`}
          >
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line })}>
                {/* <span>{i + 1}</span> */}
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token })} />
                ))}
              </div>
            ))}
          </code>
        )
      }}
    </Highlight>
  )
}

export default PrismSyntaxHighlight
