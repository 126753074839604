import React, { createContext, useState, useEffect, useCallback } from "react"

export interface IThemeContextValue {
  themeMode: "light" | "dark"
  toggleTheme: () => void
}

type ThemeMode = "light" | "dark"

export const ThemeContext = createContext<IThemeContextValue | undefined>(
  undefined
)

export const useThemeContext = (): IThemeContextValue => {
  const context = React.useContext(ThemeContext)
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider")
  }
  return context
}

interface IThemeProviderProps {
  children: React.ReactNode
}

const ThemeProvider: React.FC<IThemeProviderProps> = ({ children }) => {
  const setTheme = (newTheme: ThemeMode) => {
    if (typeof window !== "undefined") {
      localStorage.setItem("theme", newTheme)
      document.body.classList.remove(newTheme === "light" ? "dark" : "light")
      document.body.classList.add(newTheme)
      setThemeMode(newTheme)
    }
  }

  useEffect(() => {
    // Get current theme from body class
    // The theme is set in gatsby-ssr.tsx to avoid Flash of Unstyled Content (FOUC)
    const savedTheme = document.body.classList.contains("dark")
      ? "dark"
      : "light"
    setTheme(savedTheme)
  }, [])

  const [themeMode, setThemeMode] = useState<ThemeMode>("light")

  const toggleTheme = useCallback(() => {
    const newTheme = themeMode === "light" ? "dark" : "light"
    setTheme(newTheme)
  }, [themeMode])

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
