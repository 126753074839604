import type { GatsbyBrowser } from "gatsby"
import TailwindContext from "@bufox/gatsby-theme-dry/src/context/tailwind-context"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "./tailwind.config"

import "./src/styles/global.css"

const fullConfig = resolveConfig(tailwindConfig)

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <TailwindContext.Provider
      value={{
        content: fullConfig.content,
        screens: fullConfig.theme?.screens,
        colors: fullConfig.theme?.colors,
      }}
    >
      {element}
    </TailwindContext.Provider>
  )
}
