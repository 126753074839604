import { createContext, useContext } from "react"
import { graphql } from "gatsby"
import { ILocale, IMenu, IPageContext, ITranslation } from "types/models"
import { Components, MergeComponents } from "@mdx-js/react/lib"

export interface IPageInfoContext {
  pageContext: IPageContext
  menus?: {
    [position: string]: IMenu
  }
  path: string
  pageUrl: string
  translations: {
    [key: string]: ITranslation
  }
  locale: ILocale
  mdxComponents: Components | MergeComponents | null | undefined
  [key: string]: unknown
}

export const PageInfoContext = createContext<IPageInfoContext | undefined>(
  undefined
)

export const usePageInfoContext = (): IPageInfoContext => {
  const context = useContext(PageInfoContext)
  if (!context) {
    throw new Error("usePageInfoContext must be used within a PageProvider")
  }
  return context
}

export const TranslationsFragment = graphql`
  fragment SitePageTranslations on SitePageConnection {
    nodes {
      path
      pageContext
    }
  }
`

export const IntlMessagesFragment = graphql`
  fragment IntlMessages on Query {
    allIntlMessages {
      nodes {
        localeCode
        type
        strings
      }
    }
  }
`

export const MenusFragment = graphql`
  fragment Menus on MenuConnection {
    nodes {
      position
      entries
      localeCode
    }
  }
`

export const PageInfoFragment = graphql`
  fragment PageInfo on Query {
    menus: allMenu(
      filter: {
        localeCode: { eq: $localeCode }
        position: { in: $menuPositions }
      }
    ) {
      ...Menus
    }
    translations: allSitePage(filter: { path: { in: $translationsPaths } }) {
      ...SitePageTranslations
    }
    ...IntlMessages
  }
`

export default PageInfoContext
