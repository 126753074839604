import type { GatsbyBrowser } from "gatsby"
import "./src/styles/global.css"
import ThemeProvider from "context/theme-context"

import PageWrapper from "components/page-wrapper/page-wrapper"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <PageWrapper {...element.props}>{element}</PageWrapper>
}

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <ThemeProvider>{element}</ThemeProvider>
}
