import React from "react"
import { IPageInfoContext, PageInfoContext } from "context/page-info-context"
import urlJoin from "url-join"
import { IntlProvider } from "react-intl"
import merge from "lodash.merge"
import { MDXProvider } from "@mdx-js/react"
import PrismSyntaxHighlight, {
  ICodeBlockProps,
} from "components/prism-syntax-highlight/prism-syntax-highlight"
import { IMenu, IPageContext, ITranslation } from "types/models"

export interface IFlattenStringsObject {
  [key: string]: string
}

export interface INestedStringsObject {
  [key: string]: string | INestedStringsObject
}

function isNestedStringsObject(value: unknown): value is INestedStringsObject {
  return (
    typeof value === "object" &&
    value !== null &&
    !Array.isArray(value) &&
    !(value instanceof Date)
  )
}

function flattenObject(
  obj: INestedStringsObject,
  parentKey = "",
  res: IFlattenStringsObject = {}
) {
  Object.keys(obj).forEach(key => {
    const newKey = parentKey ? `${parentKey}.${key}` : key
    const value = obj[key]

    if (isNestedStringsObject(value)) {
      flattenObject(value, newKey, res)
    } else {
      res[newKey] = value
    }
  })
  return res
}

export interface IIntlMessagesInput {
  nodes: Array<{
    localeCode: string
    type?: string
    strings: INestedStringsObject
  }>
}

/**
 * Processes a list of localization nodes and returns an object containing flattened string messages for each locale.
 *
 * The function processes nodes in two steps:
 * 1. First, it processes nodes with `type` equal to `"default"`, initializing the base set of nested strings for each locale.
 * 2. Then, it processes the remaining nodes (those with `type` different from `"default"`), merging their nested strings with the already initialized base strings.
 *
 * Finally, it flattens the resulting nested objects into a single level and returns them.
 *
 * @param {Object} params - The input parameters.
 * @param {Array} params.nodes - An array of nodes, where each node represents a set of nested localized strings.
 * @param {string} params.nodes[].localeCode - The locale code associated with the node (e.g., "en", "fr").
 * @param {string} params.nodes[].type - The type of the node, either "default" or any other string.
 * @param {Object} params.nodes[].strings - An object containing the nested localized strings for the given locale.
 *
 * @returns {Object} - An object where each key is a locale code and the corresponding value is a flattened object containing localized strings.
 *
 * @example
 * const nodes = [
 *   { localeCode: "en", type: "default", strings: { greeting: { morning: "Good morning", evening: "Good evening" }, farewell: "Goodbye" } },
 *   { localeCode: "fr", type: "default", strings: { greeting: { morning: "Bonjour", evening: "Bonsoir" }, farewell: "Au revoir" } },
 *   { localeCode: "en", type: "override", strings: { greeting: { morning: "Morning" } } },
 *   { localeCode: "es", type: "default", strings: { greeting: { morning: "Buenos días", evening: "Buenas noches" }, farewell: "Adiós" } }
 * ];
 *
 * const messages = getIntlMessages({ nodes });
 *
 * // Output:
 * // {
 * //   en: { "greeting.morning": "Morning", "greeting.evening": "Good evening", "farewell": "Goodbye" },
 * //   fr: { "greeting.morning": "Bonjour", "greeting.evening": "Bonsoir", "farewell": "Au revoir" },
 * //   es: { "greeting.morning": "Buenos días", "greeting.evening": "Buenas noches", "farewell": "Adiós" }
 * // }
 */
function getIntlMessages({ nodes }: IIntlMessagesInput): {
  [localeCode: string]: IFlattenStringsObject
} {
  const intlObject: { [localeCode: string]: INestedStringsObject } = {}
  nodes.forEach(node => {
    if (node.type === "default") {
      intlObject[node.localeCode] = node.strings
    }
  })
  nodes.forEach(node => {
    if (node.type !== "default") {
      if (node.localeCode in intlObject) {
        intlObject[node.localeCode] = merge(
          intlObject[node.localeCode],
          node.strings
        )
      } else {
        intlObject[node.localeCode] = node.strings
      }
    }
  })
  const output: { [localeCode: string]: IFlattenStringsObject } = {}
  for (const locale in intlObject) {
    output[locale] = flattenObject(intlObject[locale])
  }
  return output
}

export interface IExtendedPageProps {
  path: string
  children?: React.ReactNode
  pageContext: IPageContext
  data: {
    menus?: { nodes: IMenu[] }
    allIntlMessages?: {
      nodes: Array<{
        localeCode: string
        type?: string
        strings: INestedStringsObject
      }>
    }
    translations?: {
      nodes: Array<{
        pageContext: IPageContext
        path: string
      }>
    }
  }
}

export function getPageInfoContextProps(
  pageProps: IExtendedPageProps
): IPageInfoContext {
  const siteUrl = process.env.GATSBY_SITE_URL
  if (!siteUrl) {
    throw new Error("GATSBY_SITE_URL is not set")
  }

  const pageUrl = urlJoin(siteUrl, pageProps.path || "/")

  const menus = pageProps.data?.menus?.nodes?.reduce(
    (acc: { [position: string]: IMenu }, menu) => {
      acc[menu.position] = menu
      return acc
    },
    {}
  )

  const translations: { [locale: string]: ITranslation } =
    pageProps.data?.translations?.nodes.reduce(
      (acc: { [key: string]: ITranslation }, translation) => {
        acc[translation.pageContext.locale.shortCode] = {
          locale: translation.pageContext.locale,
          path: translation.path,
        }
        return acc
      },
      {}
    ) || {}

  const locale = pageProps.pageContext.locale
  const mdxComponents = {
    code: ({ children, className }: ICodeBlockProps) => {
      return className ? (
        <PrismSyntaxHighlight className={className}>
          {children}
        </PrismSyntaxHighlight>
      ) : (
        <code className="text-accent">{children}</code>
      )
    },
  }

  const pageInfoContextProps = {
    ...pageProps,
    locale,
    mdxComponents,
    menus,
    pageUrl,
    translations,
  }

  return pageInfoContextProps
}

const PageWrapper: React.FC<IExtendedPageProps> = props => {
  const { children } = props
  const pageInfoContextProps = getPageInfoContextProps(props)
  const { locale, mdxComponents } = pageInfoContextProps
  const intlMessagesNodes = props.data?.allIntlMessages?.nodes || []
  const intlMessages = getIntlMessages({ nodes: intlMessagesNodes })
  // If an exact locale is not found, try to find a locale without the region
  if (intlMessages && !intlMessages[locale.shortCode]) {
    const shortLocale = locale.shortCode.split("-")[0]
    if (intlMessages[shortLocale]) {
      intlMessages[locale.shortCode] = intlMessages[shortLocale]
    }
  }
  return (
    <PageInfoContext.Provider value={pageInfoContextProps}>
      <IntlProvider
        locale={locale.shortCode}
        messages={intlMessages[locale.shortCode]}
      >
        <MDXProvider components={mdxComponents}>
          <div>{children}</div>
        </MDXProvider>
      </IntlProvider>
    </PageInfoContext.Provider>
  )
}

export default PageWrapper
