import type { Config } from "tailwindcss"
import colors from "tailwindcss/colors"
import plugin from "tailwindcss/plugin"
import typography from "@tailwindcss/typography"

const isDev = process.env.NODE_ENV === "development"
const conditionalPlugins = []
if (isDev) {
  const debugScreensPlugin = require("tailwindcss-debug-screens")
  conditionalPlugins.push(debugScreensPlugin)
}

const disabledCss = {
  "code::before": false,
  "code::after": false,
  "blockquote p:first-of-type::before": false,
  "blockquote p:last-of-type::after": false,
  pre: false,
  code: false,
  "pre code": false,
}

const tailwindConfig = {
  darkMode: "selector",
  content: [
    `./src/**/*.{js,jsx,ts,tsx}`,
    "../theme/src/**/*.{js,jsx,ts,tsx}",
    "./gatsby-browser.tsx",
    "./gatsby-ssr.tsx",
    "../theme/gatsby-browser.tsx",
    "../theme/gatsby-ssr.tsx",
  ],
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: "1rem",
        sm: "2rem",
        lg: "4rem",
        xl: "5rem",
      },
    },
    extend: {
      colors: {
        primary: "#1c2841", // dark blue
        secondary: colors.purple[600],
        "secondary-light": colors.purple[400],
        "secondary-dark": colors.purple[800],
        accent: "#00ccff", // cyan
        dark: colors.slate[800],
        light: colors.white,
        header: {
          bg: colors.white,
          text: colors.black,
          entries: colors.black,
          accent: colors.red[700],
          dark: {
            bg: colors.slate[800],
            text: colors.white,
            entries: colors.white,
            accent: colors.red[500],
          },
          hoverlay: {
            bg: colors.transparent,
            text: colors.black,
            entries: colors.gray[800],
            accent: colors.red[700],
            dark: {
              bg: colors.transparent,
              text: colors.white,
              entries: colors.gray[300],
              accent: colors.white,
            },
          },
          sticky: {
            bg: colors.white,
            text: colors.black,
            entries: colors.black,
            accent: colors.red[700],
            dark: {
              bg: colors.slate[800],
              text: colors.white,
              entries: colors.white,
              accent: colors.red[500],
            },
          },
        },
        "content-header": {
          bg: "rgba(225, 232, 237, 0.7)",
          dark: {
            bg: "rgba(0, 0, 0, 0.7)",
          },
        },
      },
      animation: {
        "dynamic-gradient": "dynamic-gradient 15s ease infinite",
      },
      keyframes: {
        "dynamic-gradient": {
          "0%": {
            "background-position": "0% 50%",
          },
          "50%": {
            "background-position": "100% 50%",
          },
          "100%": {
            "background-position": "0% 50%",
          },
        },
      },
      typography: {
        DEFAULT: { css: disabledCss },
        sm: { css: disabledCss },
        lg: { css: disabledCss },
        xl: { css: disabledCss },
        "2xl": { css: disabledCss },
      },
    },
  },
  plugins: [
    typography,
    plugin(function ({ addVariant }) {
      addVariant("hoverlay", ".overlay-header &")
    }),
    plugin(function ({ addVariant }) {
      addVariant("hoverlay-dark", ".overlay-header.overlay-header-dark &")
    }),

    plugin(function ({ addVariant }) {
      addVariant("hm-open", ".header-modal-open &")
    }),

    plugin(function ({ addVariant }) {
      addVariant("hsticky", ".headroom--pinned &")
    }),

    plugin(function ({ addVariant }) {
      addVariant("sr-open", ".search-results.open &")
    }),
    ...conditionalPlugins,
  ],
} satisfies Config

export default tailwindConfig
